import { Component, EventEmitter, inject, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { MeterInfoElectricityComponent } from '../meter-info-electricity/meter-info-electricity.component';
import { MeterInfoGasComponent } from '../meter-info-gas/meter-info-gas.component';
import { ElectricityMeterStore } from '@smooved-lib/lib/meters/store/electricity-meter.store';
import { GasMeterStore } from '@smooved-lib/lib/meters/store/gas-meter.store';

@Component({
    selector: 'app-meter-readings',
    template: `
        <form class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-meter-info-electricity [isEots]="isEots()"></app-meter-info-electricity>

            <app-meter-info-gas [isEots]="isEots()"></app-meter-info-gas>

            <app-previous-submit [showPrevious]="false" [showSkipStep]="true" (skipStep)="skipStep.emit()"></app-previous-submit>
        </form>
    `,
    styles: [],
})
export class MeterReadingsComponent implements OnDestroy {
    @Output() public next: EventEmitter<string> = new EventEmitter<string>();
    @Output() public skipStep: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(MeterInfoElectricityComponent, { static: true }) public meterInfoElectricityComponent: MeterInfoElectricityComponent;
    @ViewChild(MeterInfoGasComponent, { static: true }) public meterInfoGasComponent: MeterInfoElectricityComponent;

    private electricityMeterStore = inject(ElectricityMeterStore);
    private gasMeterStore = inject(GasMeterStore);

    constructor(
        private moveSandbox: MoveSandbox,
        private route: ActivatedRoute
    ) {}

    public isEots(): boolean {
        return this.route.snapshot.queryParams.eots === 'true';
    }

    public onSubmit(event): void {
        if (this.meterInfoElectricityComponent.form.valid && this.meterInfoGasComponent.form.valid) {
            this.next.emit();
        }
    }

    public ngOnDestroy(): void {
        this.moveSandbox.idOnce$.subscribe((id) => {
            this.electricityMeterStore.patch(id, this.meterInfoElectricityComponent.createPatch());
            this.gasMeterStore.patch(id, this.meterInfoGasComponent.createPatch());
        });
    }
}
